@import "../../../styles/variables";
@import "../../../styles/mixins";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &.wide {
    @include fixed(0, 0, 0, 0);
    z-index: 15;
    background-color: $white;
  }
}
