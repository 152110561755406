@import '../../../../styles/variables';

.info {
	strong {
		font-weight: $semiBold;
	}

	& + .info {
		margin-top: 16px;
	}
}

.action {
	margin-top: 40px;
}
