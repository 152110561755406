@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.multi-value-input {
	.clear {
		cursor: pointer;
		background-image: url('../../../../assets/images/icon-cross-thin-accent-dark.svg');
		background-size: 12px 12px;
		background-repeat: no-repeat;
		background-position: center;
		width: 32px;
		height: 100%;

		&:hover {
			background-color: $primary_lavender_50;
		}
	}
}

.label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	font-weight: $medium;
	color: $primary_blueberry_200;
	margin-bottom: 4px;
}

.input {
	border: 1px solid $primary_lavender_75;
	background-color: $primary_lavender_25;
	border-radius: 12px;
	color: $primary_blueberry_200;
	font-size: 14px;
	line-height: 20px;
	padding: 8px 12px;
	outline: none;
	width: 100%;
	transition: all 0.2s linear;

	&::placeholder {
		color: $primary_blueberry_100;
	}

	&:hover {
		background-color: $primary_lavender_50;
	}

	&:focus,
	&:active {
		background-color: $primary_lavender_25;
		border-color: $primary_lavender_200;
	}

	&:disabled,
	&:read-only {
		background-color: $primary_lavender_25;
		border-color: $primary_lavender_50;
		color: $primary_blueberry_75;
		cursor: not-allowed;
	}
}

.negative {
	.label {
		color: $primary_lavender_25;
	}

	.input {
		background: $primary_blueberry_500;
		border-radius: 12px;
		border: 1px solid $primary_lavender_500;
		color: $primary_lavender_25;

		&::placeholder {
			color: $primary_lavender_100;
		}

		&:focus,
		&:active {
			border-color: $primary_lavender_300;
		}
	}
}

.error {
	.input {
		border-color: $system_red_300;
	}
}

.disabled {
	&.error {
		.input {
			border-color: $system_red_75;
		}
	}
}
