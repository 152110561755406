@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-assets {
	.actions, .table-wrap {
		margin-top: 32px;
	}

	.actions {
		.group {
			.action {
				& + .action  {
					margin-left: 16px;
				}
			}
		}
	}
}
