@import '../../../styles/variables';
@import '../../../styles/mixins';

.text-button {
	color: $primary_blueberry_400;
	font-size: 12px;
	font-weight: $semiBold;
	cursor: pointer;

	&:hover {
		color: $primary_blueberry_100;
	}
}
