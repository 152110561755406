@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.edit-profile {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.input {
			& + .input {
				margin-top: 24px;
			}
		}
	}
}
