@import '../../styles/variables';
@import '../../styles/mixins';

.scrollbar {
	padding-right: 16px;
	margin-bottom: 32px;

	:global {
		.ps__rail-y {
			background-color: $primary_lavender_25 !important;
		}
	}
}

.frameworks {
	max-height: calc(100vh - 100px);
	display: grid;
	grid-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));

	.framework {
		padding: 24px;
		position: relative;
		min-width: 435px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.main {
			.title {
				margin-bottom: 16px;

				h3,
				img {
					display: inline;
					vertical-align: middle;
				}

				img {
					margin-right: 8px;
					height: 32px;
				}

				&.with-tag {
					padding-right: 80px;
				}
			}

			.description {
				font-size: 12px;
				line-height: 16px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;

				&.clamp-5 {
					-webkit-line-clamp: 5;
				}
				&.clamp-7 {
					-webkit-line-clamp: 7;
				}
				&.clamp-8 {
					-webkit-line-clamp: 8;
				}
				&.clamp-11 {
					-webkit-line-clamp: 11;
				}
			}

			.tag {
				position: absolute;
				top: 28px;
				right: 24px;
			}

			.more {
				margin-top: 8px;
				text-align: right;
			}
		}

		.bottom {
			margin-top: 16px;

			.actions {
				margin-top: 0;

				.action {
					&.with-loader {
						border: none;
						background: linear-gradient(90deg, #a195eb 33%, #7967e3 50%, #a195eb 66%)
							#f2f2f2;
						background-size: 300% 100%;
						animation: l1 1s infinite linear;
					}

					@keyframes l1 {
						0% {
							background-position: right;
						}
					}
				}
			}
		}
	}
}

.details {
	font-size: 14px;
	line-height: 20px;
}

.frameworks-skeleton {
	height: 100%;
	display: grid;
	grid-template-columns: calc(50% - 8px) calc(50% - 8px);
	justify-content: space-between;

	@include breakpoint($largeDesktop) {
		grid-template-columns: calc(33% - 8px) calc(33% - 8px) calc(33% - 8px);
	}

	.framework {
		border-radius: 16px;
		margin-bottom: 24px;
	}
}
