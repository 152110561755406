@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 528px;

	form {
		margin-top: 24px;

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.actions {
			margin-top: 32px;
		}
	}
}
