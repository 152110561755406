@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 712px;
	height: 456px;

	.title {
		margin-bottom: 24px;
	}

	.text-wrap {
		height: 330px;

		.text {
			padding-right: 16px;

			p {
				white-space: break-spaces;
			}
		}
	}
}
