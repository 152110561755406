@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-asset {
	form {
		margin-top: 32px;

		.input {
			& + .input {
				margin-top: 16px;
			}
		}

		.actions {
			margin-top: 32px;
		}

		.table-wrap  {
			margin-top: 16px;
		}
	}
}
