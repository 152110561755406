@import '../../../styles/variables';
@import '../../../styles/mixins';

.primary-framework {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.top {
		.title-section {
			display: flex;
			flex-direction: row;
			margin-bottom: 12px;
			align-items: center;
			justify-content: space-between;

			h3 {
				margin-bottom: 0;
			}
		}

		.framework {
			.title {
				margin-bottom: 16px;

				h4,
				img {
					display: inline;
					vertical-align: middle;
				}

				img {
					margin-right: 8px;
					height: 32px;
				}
			}

			.description {
				font-size: 12px;
				line-height: 16px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;

				@include breakpoint(1700px) {
					-webkit-line-clamp: 4;
				}
			}
		}
	}

	.bottom {
		margin-top: 16px;
	}
}
