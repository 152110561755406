@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 393px;
	height: auto;
	padding: 32px 24px;

	.title {
		margin-bottom: 24px;
	}

	.text {
		margin-bottom: 32px;
	}
}
