@import '../../../styles/variables.scss';

.scrollbar {
	:global {
		.ps__rail-y {
			width: 8px;
			margin: 4px 0;
			background-color: $primary_lavender_50 !important;
			border-radius: 24px;
			opacity: 1 !important;
		}

		.ps__rail-y:hover,
		.ps__rail-y:focus,
		.ps__rail-y.ps--clicking {
			background-color: $primary_lavender_50;
		}

		.ps__thumb-y {
			background-color: $primary_lavender_75;
			border-radius: 24px;
			width: 8px;
			right: 0;
		}

		.ps__rail-y:hover > .ps__thumb-y,
		.ps__rail-y:focus > .ps__thumb-y,
		.ps__rail-y.ps--clicking .ps__thumb-y {
			background-color: $primary_lavender_75;
			width: 8px;
		}
	}

	&.small {
		:global {
			.ps__rail-y {
				width: 4px;
			}

			.ps__thumb-y {
				width: 4px;
			}

			.ps__rail-y:hover > .ps__thumb-y,
			.ps__rail-y:focus > .ps__thumb-y,
			.ps__rail-y.ps--clicking .ps__thumb-y {
				width: 4px;
			}
		}
	}
}
