@import '../../../styles/variables';
@import '../../../styles/mixins';

.close {
	cursor: pointer;
	position: absolute;
	top: 42px;
	right: 36px;
	width: 24px;
	height: 24px;
	padding: 6px;
	border-radius: 4px;

	&:before {
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		background: url('../../../assets/images/icon-close.svg') no-repeat;
		background-size: contain;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: $primary_lavender_50;
	}
}
