@import '../../../styles/variables.scss';

.data-list-item {
	display: flex;
	flex-direction: row;
	padding: 3px 0;

	& + .data-list-item {
		margin-top: 16px;
	}

	.label,
	.text {
		font-size: 14px;
		line-height: 20px;
		
	}

	.label {
		width: 89px;
		font-weight: $semiBold;
		color: $primary_blueberry_100;
		margin-right: 16px;
	}

	.text {
		color: $primary_blueberry_500;
	}
}
