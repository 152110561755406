@import '../../../styles/variables.scss';

.file-picker {
	.drag-n-drop {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		color: $primary_blueberry_100;
		outline: none;
		transition: border 0.24s ease-in-out;
		border: 1px solid $primary_lavender_75;
		background-color: $primary_lavender_25;
		border-radius: 12px;
		cursor: pointer;
		height: 94px;

		&.focused {
			border-color: $primary_lavender_75;
		}

		&.accepted {
			border-color: $primary_lavender_100;
		}

		&.rejected {
			border-color: $system_red_300;
		}

		& > * {
			color: $primary_blueberry_100;
		}
	}

	.divider {
		margin: 16px 0;
		text-align: center;
		font-size: 12px;
	}
}
