@import '../../../../../styles/mixins';

.basic-panel {
	padding: 0 0 12px;
	position: relative;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.name,
.description {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.description {
	font-size: 12px;
	line-height: 16px;
	margin-top: 8px;
}

.add {
	margin-left: 32px;
}

.filters {
	.search {
		width: 236px;

		@include breakpoint(0, $mediumDesktop) {
			width: 328px;
		}
	}

	.assign {
		padding-top: 8px !important;
		padding-left: 8px !important;
		padding-bottom: 4px !important;
	}

	.left {
		display: flex;
		flex-wrap: wrap;

		&.disabled {
			opacity: 0.8;
			pointer-events: none;
		}
	}
}
