@import '../../styles/variables.scss';

.scrollbar {
	padding-right: 16px;
	margin-bottom: 32px;

	:global {
		.ps__rail-y {
			background-color: $primary_lavender_25 !important;
		}
	}
}

.panels {
	max-height: calc(100vh - 100px);
	display: grid;
	grid-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));

	.basic-panel {
		min-width: 435px;
		padding: 16px 20px;
		min-height: 200px;

		&.updates {
			grid-row: span 2;
			padding-bottom: 24px;
			height: max-content;
			min-height: 200px;
		}

		&.gs-updates {
			max-height: 296px;
		}

		.loader {
			display: inline-block;
		}
	}
}

.home-skeleton {
	display: grid;
	grid-gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));

	br {
		display: none;
	}

	.skeleton {
		display: inline-block;
		min-width: 435px;

		&.updates {
			grid-row: span 2;
		}
	}

	.skeleton-item {
		border-radius: 16px;
		height: -webkit-fill-available;
	}
}
