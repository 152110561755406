.basic-panel {
	padding: 0 0 12px;
	position: relative;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.name-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.alert-tag {
		margin: 8px 0 0;
	}
}
