@import '../../../styles/variables';
@import '../../../styles/mixins';

.user-profile {
	display: block;
	position: relative;
	text-decoration: none;
	outline: none;
	background: linear-gradient(90deg, rgba(93, 72, 221, 0.6) 1.39%, rgba(93, 72, 221, 0.2) 100%);
	border-radius: 24px;
	transition: all 0.1s linear;
	min-height: 96px;

	&:after {
		content: '';
		position: absolute;
		right: 32px;
		top: calc(50% - 7px);
		background-image: url('../../../assets/images/icon-chevron-right-light.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 12px;
		width: 6px;
	}

	&.active {
		&:after {
			display: none;
		}
	}

	.user-block {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		background: $primary_blueberry_400;
		position: absolute;
		top: 1px;
		bottom: 1px;
		left: 1px;
		right: 1px;
		padding: 24px 16px;
		border-radius: 24px;

		.avatar {
			flex-shrink: 0;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background: $primary_lavender_400;
			margin-right: 8px;
			color: $primary_lavender_50;
			font-weight: 600;
			text-transform: uppercase;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			line-height: 32px;
			border: 2px solid $primary_lavender_300;
			transition: all 0.1s linear;
		}

		.info {
			font-size: 14px;
			line-height: 20px;
			padding-right: 30px;

			.name {
				color: $primary_lavender_25;

				font-weight: $semiBold;
			}

			.title {
				color: $primary_lavender_100;
			}
		}
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		position: relative;
		background: $primary_blueberry_400;
		border: 1px solid rgba(93, 72, 221, 0.5);
		border-right: none;

		.user-block {
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(
				90deg,
				#5d48dd 0%,
				rgba(93, 72, 221, 0.536233) 28.5%,
				rgba(93, 72, 221, 0) 100%
			);
			border-radius: 22px;

			.avatar {
				background: $primary_lavender_50;
				color: $primary_lavender_300;
				border-color: $primary_lavender_100;
			}
		}
	}
}

