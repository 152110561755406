@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.edit-tag {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.inputs {
			max-height: calc(100vh - 200px);

			.details {
				margin-top: 0;
			}

			.input {
				& + .input {
					margin-top: 24px;
				}
			}

			.section {
				margin: 32px 0;

				.title {
					font-size: 11px;
					font-weight: $medium;
					margin-bottom: 16px;
				}
			}
		}

		.scrollbar {
			padding-right: 16px;
		}

		.collapsible-trigger {
			margin-bottom: 0;
		}
	}
}

.edit-tag-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.section {
		margin-top: 32px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.inputs {
		.input {
			& + .input {
				margin-top: 24px;
			}
		}
	}
}
