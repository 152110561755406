@import '../../../styles/variables';

.tags-and-controls-block {
	.tag-group {
		margin-bottom: 24px;
		border-radius: 8px;
		position: relative;
		transition: all 0.2s linear;

		&:not(.no-hover):hover {
			background-color: $primary_lavender_10;

			.remove {
				opacity: 1;
				visibility: visible;
			}
		}

		.block-title {
			padding: 0 8px 0 10px;
			margin-bottom: 8px;

			.title {
				margin-right: 8px;
				display: inline-block;
			}
		}

		.controls {
			padding: 8px;
		}

		.remove {
			position: absolute;
			top: 4px;
			right: 8px;
			opacity: 0;
			transition: opacity 0.2s linear;
			visibility: hidden;

			&:hover {
				& ~ .controls {
					.tag {
						background-color: $system_red_50;
						border-color: $system_red_75 !important;
					}
				}
			}
		}

		.tag {
			&.added-control {
				background-color: $system_green_50;
				border-color: $system_green_75 !important;
			}
		}
	}

	.actions {
		margin-top: 24px;
		text-align: right;

		.action {
			& + .action {
				margin-left: 8px;
			}
		}
	}

	.empty {
		font-size: 14px;
		line-height: 20px;
		font-weight: $normal;
		color: $primary_blueberry_100;
	}
}
