@import '../../../styles/variables';

.title {
	margin-bottom: 32px;

	h3 {
		display: inline-block;
		margin: 0;
	}

	.back {
		position: relative;
		display: inline-block;
		top: 0;
		left: 0;
		margin-right: 20px;
	}

	.logo {
		display: inline-block;
		vertical-align: bottom;
		margin-right: 8px;
		height: 32px;
	}
}

.info {
	margin: 40px 0 16px;
}

.loading-progress {
	margin: 40px 0;

	.info {
		margin-top: 8px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.text,
		.percent {
			font-size: 12px;
		}
	}
}

.files {
	margin: 32px 0;
}

.actions {
	margin-top: 40px;
}

.error {
	margin-top: 40px;
	text-align: center;
}
