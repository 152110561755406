@import '../../../styles/variables.scss';

.image-picker {
	.drag-n-drop {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		color: $primary_blueberry_200;
		outline: none;
		transition: border 0.24s ease-in-out;
		border: 1px solid $primary_blueberry_75;
		border-radius: 8px;
		cursor: pointer;
		height: 98px;

		&.focused {
			border-color: $primary_blueberry_75;
		}

		&.accepted {
			border-color: $primary_blueberry_75;
		}

		&.rejected {
			border-color: $system_red_300;
		}

		& > * {
			color: $primary_blueberry_100;
		}
	}

	.logo-group {
		display: flex;
		flex-direction: row;

		.logo {
			width: 170px;
			height: 80px;
			border: 1px solid $primary_lavender_75;
			background-color: $primary_lavender_50;
			border-radius: 8px;
			margin-bottom: 24px;
			background-image: url('../../../assets/images/icon-image-stub.svg');
			background-repeat: no-repeat;
			background-position: center;
			margin-right: 24px;

			&.hide {
				border: none;
				background: none;
			}

			&.error {
				border-color: $system_red_300;
			}

			.preview {
				height: 80px;
				width: 170px;
				overflow: hidden;
				position: relative;

				&.uploading {
					border: 1px solid $primary_lavender_50;
					border-radius: 8px;

					.image {
						opacity: 0.2;
					}
				}

				.image {
					max-width: 100%;
					max-height: 100%;
					border-radius: 8px;
				}

				.progress-wrap {
					position: absolute;
					bottom: 16px;
					left: 0;
					right: 0;

					.percent {
						color: $primary_lavender_300;
						font-size: 12px;
						font-weight: $semiBold;
						text-align: center;
					}

					.progress {
						margin: 0 12px;
					}
				}
			}
		}

		.upload {
			display: flex;
			flex-direction: column;

			.actions {
				.delete {
					margin-left: 16px;
				}
			}

			.text {
				max-width: 190px;
				color: $primary_blueberry_100;
				margin-top: 16px;
				font-size: 12px;
				line-height: 15px;

				&.error {
					color: $system_red_300;
				}
			}
		}
	}
}
