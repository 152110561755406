.basic-panel {
	padding: 0 0 12px;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.name,
.description {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.description {
	font-size: 12px;
	line-height: 16px;
	margin-top: 8px;
}
