@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.create-tag-details {
	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.inputs {
			margin-top: 32px;
		}

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.section {
			margin: 24px 0 0;

			.title {
				font-size: 11px;
				font-weight: $medium;
				margin-bottom: 16px;
			}
		}

		.actions {
			margin-top: 32px;
		}
	}
}
