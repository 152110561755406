@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.policy-details {
	.title {
		margin-bottom: 16px;
	}

	.table {
		margin-top: 24px;
	}
}
