@import '../../../../styles/variables.scss';

.section {
	margin-top: 32px;

	.section-title {
		margin-bottom: 24px;
	}

	br {
		display: none;
	}
}
