@import '../../../styles/variables.scss';

.progress-bar-wrapper {
    height: 5px;
    border-radius: 8px;
    background: $primary_lavender_50;
    position: relative;

    .progress {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 8px;
        background: $primary_lavender_300;
    }
}