@import '../../../../styles/variables';

.tasks {
	h3 {
		margin-bottom: 0;
	}

	.empty-message {
		text-align: center;
		margin-top: 16px;

		.message {
			position: relative;
			display: inline-block;
			color: $primary_blueberry_100;
			font-size: 14px;

			&:before {
				content: '';
				display: block;
				width: 64px;
				height: 64px;
				background: url('../../../../assets/images/icon-empty-tasks.svg') no-repeat center;
				background-size: contain;
				margin: 0 auto 16px;
			}
		}
	}
}

.date-tag {
	margin: -4px 0;
}

.related-item {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
