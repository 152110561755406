@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.add-asset {
	form {
		margin-top: 32px;

		.input-group {
			margin: 16px 0;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;

			.input {
				margin-top: 0;
				flex: 0 1 calc(50% - 8px);
			}
		}

		.actions {
			margin-top: 32px;
		}
	}
}
