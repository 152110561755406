@import '../../../styles/mixins';

.tabs {
	width: 100%;

	.tabs-wrap {
		position: relative;
		margin-bottom: 16px;
		background: $white;
		border-radius: 12px;
		padding: 4px;
		width: max-content;
	}
}
