@import '../../../../styles/variables';

.table-wrap {
	.filters {
		padding: 0;
		margin-bottom: 16px;
	}

	.remove {
		margin-top: 8px;
		margin-left: 8px;
	}

	.empty {
        color: $primary_blueberry_100;
    }

	.action-button {
		top: -6px;
	}
}
