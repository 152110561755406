@import '../../../../../styles/variables';

.options-list {
	width: 100%;
	max-height: 160px;

	&.grouped {
		max-width: 100%;

		.item {
			white-space: wrap;
		}
	}

	.item {
		padding: 8px 24px 8px 16px;
		font-size: 14px;
		line-height: 20px;
		color: $primary_blueberry_200;
		transition: all 0.1s linear;
		cursor: pointer;
		white-space: nowrap;
		border-bottom: 0.5px solid $primary_lavender_50;

		&:hover,
		&.selected {
			background-color: $primary_lavender_50;
		}

		&.selected {
			position: relative;
			background-color: $primary_lavender_50;

			&:after {
				content: '';
				position: absolute;
				width: 12px;
				height: 8px;
				top: calc(50% - 4px);
				right: 8px;
				background: url('../../../../../assets/images/icon-check-dark.svg') no-repeat;
				background-size: contain;
			}
		}

		&.empty-item {
			color: $primary_blueberry_75;
		}
	}

	.group {
		margin: 12px 0;

		&:first-child {
			.group-title {
				margin-top: 0;
			}
		}

		.group-title {
			font-size: 16px;
			font-weight: $medium;
			margin-left: 8px;
			margin-top: 24px;
		}

		.items {
			margin-top: 8px;
		}
	}

	.no-options {
		padding: 8px 16px;
		font-size: 14px;
		line-height: 20px;
		color: $primary_blueberry_75;
	}
}
