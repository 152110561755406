@import '../../../styles/variables.scss';

.collapsible-panel {
	margin-bottom: 24px;
	position: relative;

	.trigger {
		cursor: pointer;
		transition: all 500ms linear;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 24px;
			height: 24px;
			background-color: $white;
			z-index: 1;
			padding-left: 8px;
		}

		&:after {
			content: '';
			position: absolute;
			width: 24px;
			height: 24px;
			top: 0;
			right: 0;
			background-image: url('../../../assets/images/icon-chevron-right-light.svg');
			background-repeat: no-repeat;
			background-size: 10px 10px;
			background-position: center;
			transform: rotate(-270deg);
			z-index: 1;
		}

		&.opened {
			&:after {
				transform: rotate(270deg);
			}
		}
	}

	:global {
		.ReactCollapse--collapse {
			transition: height 500ms;
		}
	}
}
