@import '../../../styles/variables.scss';

.alert {
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 352px;
	font-size: 14px;
	line-height: 22px;
	color: $primary_blueberry_500;
	border-radius: 8px;
	opacity: 0;
	min-height: 40px;
	max-width: 50%;
	padding: 8px 16px;
	transition: all 0.2s linear;
	visibility: hidden;

	&.visible {
		visibility: visible;
		opacity: 1;
		bottom: 32px;
		z-index: 100;
	}

	&:before {
		content: '';
		background-repeat: no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: bottom;
		margin-right: 8px;
	}

	&.success {
		background-color: $system_green_200;

		&:before {
			background-image: url('../../../assets/images/icon-check-in-circle-dark.svg');
		}
	}

	&.error {
		background-color: $system_red_200;

		&:before {
			background-image: url('../../../assets/images/icon-exclamation-in-circle-dark.svg');
		}
	}

	&.warning {
		background-color: $system_orange_200;

		&:before {
			background-image: url('../../../assets/images/icon-info-dark.svg');
		}
	}
	@media screen and (max-width: 800px) {
		& {
			left: 15px;
			right: 15px;
			position: fixed;
			max-width: 100%;
		}
	}
}
