@import '../../../styles/variables';
@import '../../../styles/mixins';

.error {
	font-size: 12px;
	line-height: 16px;
	color: $system_red_300;
	margin-top: 4px;

	& > * {
		color: $system_red_300;
	}

	&.disabled {
		color: $system_red_75;
	}
}
