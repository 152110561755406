@import '../../../styles/variables';
@import '../../../styles/mixins';

.navigation {
	.items {
		.item {
			display: block;
			text-decoration: none;
			background: transparent;
			color: $primary_lavender_25;
			padding: 14px 24px;
			transition: all 200ms linear;
			font-size: 14px;
			line-height: 20px;
			font-weight: $semiBold;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;
				width: 20px;
				height: 20px;
				background-size: contain;
				background-repeat: no-repeat;
			}

			&:hover,
			&:active,
			&:focus,
			&.active {
				background: linear-gradient(
					90deg,
					$primary_lavender_300 0%,
					rgba(93, 72, 221, 0.536233) 28%,
					rgba(93, 72, 221, 0) 100%
				);
				border-color: rgba(93, 72, 221, 0.5);
			}

			& + .item {
				margin-top: 8px;
			}

			&.home:before {
				background-image: url('../../../assets/images/icon-home.svg');
			}
			&.frameworks:before {
				background-image: url('../../../assets/images/icon-frameworks.svg');
			}
			&.controls:before {
				background-image: url('../../../assets/images/icon-server.svg');
			}
			&.policies:before {
				background-image: url('../../../assets/images/icon-policies.svg');
			}
			&.shareholders:before {
				background-image: url('../../../assets/images/icon-users.svg');
			}
			&.assets:before {
				background-image: url('../../../assets/images/icon-package.svg');
			}
			&.companyProfile:before {
				background-image: url('../../../assets/images/icon-building.svg');
			}
		}
	}
}
