@import '../../../../styles/variables';

.info {
	margin-bottom: 16px;
}

.form {
	.actions {
		margin-top: 40px;
	}
}
