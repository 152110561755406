@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 712px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.title {
		margin-bottom: 24px;
		line-height: 40px;
	}

	.actions {
		margin-top: 32px;
	}
}
