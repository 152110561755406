@import '../../../styles/variables';

.comments-block {
	width: 100%;

	.comments {
		margin-bottom: 24px;

		.comment {
			.top {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 8px;

				.avatar {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					background: $primary_lavender_75;
					margin-right: 8px;
					color: $primary_blueberry_200;
					font-weight: 600;
					text-transform: uppercase;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: center;
					font-size: 10px;
					line-height: 10px;
					border: 2px solid $primary_lavender_100;
					transition: all 0.1s linear;
				}

				.name,
				.date {
					color: $primary_blueberry_100;
				}

				.name {
					font-size: 14px;
					line-height: 20px;
					font-weight: $semiBold;
				}

				.date {
					margin-left: 24px;
					font-size: 11px;
					line-height: 16px;
					font-weight: $medium;
				}
			}

			.bottom {
				font-size: 14px;
				line-height: 20px;
				color: $black;
			}

			& + .comment {
				margin-top: 24px;
			}
		}
	}

	.add-comment {
		margin-top: 8px;
		display: block;
		margin-left: auto;
	}

	.empty {
		font-size: 14px;
		line-height: 20px;
		font-weight: $normal;
		color: $primary_blueberry_100;
	}

	.loader {
		margin: 0 auto;
	}
}
