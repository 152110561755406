@import '../../../styles/variables';

.progress-wrap {
	height: 20px;
	display: flex;
	flex-direction: row;

	.percent {
		margin-left: 4px;
		font-size: 10px;
		font-weight: $medium;
		color: $primary_blueberry_200;
	}
}