@import '../../styles//mixins';
@import '../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-policy {
	flex-grow: 1;

	.status-info {
		margin-bottom: 32px;
	}

	form {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.details {
			margin-top: 0;
			margin-bottom: 0;
		}

		.inputs {
			max-height: calc(100vh - 200px);

			.input {
				width: 100%;

				& + .input {
					margin-top: 24px;
				}
			}

			.input-group {
				margin: 24px 0;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: flex-start;

				.input {
					margin-top: 0;
					flex: 0 1 calc(50% - 8px);
				}

				.input-group {
					margin: 0;
					flex: 0 1 calc(50% - 8px);
					align-items: flex-end;
				}
			}

			.input-with-info {
				width: 100%;
				margin-top: 24px;
				position: relative;

				.info {
					position: absolute;
					right: 0;
					top: -4px;
				}
			}

			.note {
				font-size: 14px;
				line-height: 20px;
				color: $primary_blueberry_100;
				margin-bottom: 24px;
			}
		}

		.actions {
			padding-right: 16px;
			justify-content: flex-end;

			.group {
				display: flex;
				flex-direction: row;

				.action + .action {
					margin-left: 16px;
				}
			}
		}

		.scrollbar {
			padding-right: 16px;
		}
	}

	.collapsible-trigger {
		margin-bottom: 0;
	}
}

.edit-policy-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.section {
		margin-top: 32px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.inputs {
		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: grid;
			grid-template-columns: calc(50% - 8px) calc(50% - 8px);
			justify-content: space-between;

			.input-group {
				margin-top: 0;
			}

			&.trio {
				margin-bottom: 0;
			}
		}

		.input-with-info {
			margin-top: 24px;
		}
	}
}
