@import '../../styles/variables.scss';

.gs-updates {
	.scrollbar {
		padding-right: 16px;
	}

	.posts {
		max-height: calc(100vh - 100px);
		display: grid;
		grid-gap: 24px;
		grid-template-columns: repeat(auto-fit, minmax(468px, 1fr));

		.post {
			display: flex;
			flex-direction: row;
			padding: 20px 16px;
			position: relative;

			.picture {
				width: 160px;
				height: 136px;
				border-radius: 8px;
				flex-shrink: 0;
				margin-right: 12px;

				img {
					border-radius: 8px;
					height: 100%;
					width: 100%;
					object-fit: contain;
				}

				&.empty {
					background-color: $primary_blueberry_50;
				}
			}

			.info {
				padding-bottom: 24px;
				padding-right: 16px;

				.label {
					margin: 0;
				}

				.title,
				.content {
					font-size: 14px;
					line-height: 20px;
				}

				.title {
					font-weight: $semiBold;
					margin-top: 8px;
				}

				.content {
					margin-top: 8px;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.read-more {
				position: absolute;
				bottom: 16px;
				right: 16px;
			}
		}
	}

	.drawer-content {
		white-space: break-spaces;

		p {
			margin-top: 0;
			color: $primary_blueberry_200;
		}
	}
}
