@import '../../../styles/variables.scss';

.status-info {
	border-radius: 4px;
	padding: 4px 8px;
	font-size: 12px;
	line-height: 16px;
	margin-top: 16px;

	&.success {
		background-color: $secondary_leaf_75;
	}

	&.warning {
		background-color: $secondary_sunflower_75;
	}

	&.error {
		background-color: $system_red_75;
	}
}
