@import '../../../styles/variables';

.info {
	& + .info {
		margin-top: 16px;
	}
}

.cards {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;

	.card {
		width: 305px;

		.title {
			margin-bottom: 16px;

			h3,
			img {
				font-weight: $semiBold;
				display: inline;
				vertical-align: middle;
			}

			img {
				margin-right: 8px;
				height: 32px;
			}
		}

		.description {
			font-size: 12px;
			line-height: 16px;
			color: $primary_blueberry_200;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 10px;
		}

		.more {
			text-align: right;
			margin-bottom: 18px;
		}
	}

	[class*="swiper-horizontal"] {
		padding-bottom: 30px;
	}

	[class*="swiper-pagination-bullets"] {
		position: relative;
	}
}

.framework {
	h4 {
		margin-top: 24px;
	}
}

.details {
	font-size: 14px;
	line-height: 20px;
	margin-top: 24px;
}
