@import '../../../styles/variables';
@import '../../../styles/mixins';

.sidebar {
	background-color: $primary_blueberry_400;
	flex: 0 1 312px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: nowrap;
	overflow: auto;

	.top {
		.logo {
			background: url('../../../assets/images/logo-gravity-grc-for-dark-bg.svg') no-repeat;
			background-size: contain;
			width: 142px;
			height: 30px;
			margin: 24px 24px 32px;
			text-decoration: none;
			padding: 0;
			display: block;
		}
	}

	.bottom {
		margin: 24px 18px;
	}
}
