@import '../../styles/variables';

.basic-panel {
	padding: 24px;
	width: 524px;
	min-height: 312px;
	position: relative;

	.edit {
		position: absolute;
		right: 24px;
		top: 22px;
	}

	.logo {
		width: 170px;
		height: 80px;
		margin: 24px 0;

		&.stub {
			border: 1px solid $primary_lavender_75;
			background-color: $primary_lavender_50;
			border-radius: 8px;
			background-image: url('../../assets/images/icon-image-stub.svg');
			background-repeat: no-repeat;
			background-position: center;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.company-skeleton {
	.skeleton {
		display: inline-block;
		width: 50%;

		&.logo {
			margin-bottom: 32px;
		}
	}

	br {
		display: none;
	}
}