@import '../../../styles/variables';

.change-history-block {
	width: 100%;

	.change-history-group {
		&:first-child {
			.group-title {
				margin-top: 0;
			}
		}

		.group-title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-items: center;
			margin-top: 28px;

			.date {
				font-size: 11px;
				line-height: 16px;
				font-weight: $medium;
				color: $primary_blueberry_100;
				white-space: nowrap;

				&.in-regular {
					margin-bottom: 8px;
				}
			}

			.entity {
				width: 75%;

				.type {
					text-transform: uppercase;
					font-size: 11px;
					line-height: 16px;
					color: $primary_blueberry_100;
					margin-right: 8px;
					font-weight: $semiBold;
					vertical-align: middle;
					display: inline-block;
				}

				.title-tag {
					max-width: 70%;
					overflow: hidden;
					text-overflow: ellipsis;
					vertical-align: middle;
					display: inline-block;
				}
			}
		}

		.change-history-item {
			& + .change-history-item {
				margin-top: 12px;
			}

			.text {
				.change-text {
					padding-left: 16px;
					font-size: 14px;
					line-height: 20px;
					font-weight: $normal;
					color: $primary_blueberry_500;

					strong {
						font-weight: $semiBold;
					}

					.highlighted {
						background-color: $primary_lavender_50;
						color: $primary_lavender_400;
					}
				}

				&.with-entities {
					.change-text {
						margin-top: 16px;
					}
				}
			}
		}
	}

	.empty {
		font-size: 14px;
		line-height: 20px;
		font-weight: $normal;
		color: $primary_blueberry_100;
	}
}
