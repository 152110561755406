@import '../../../styles/variables';

.variable-item {
	position: relative;
	display: inline-block;

	.tag {
		cursor: pointer;
		color: $primary_blueberry_200 !important;

		&.disabled {
			opacity: 0.7;
			cursor: not-allowed;
			pointer-events: none;
		}

		&.add {
			background-color: $secondary_sunflower_75 !important;
			border-color: $secondary_sunflower_200 !important;
		}

		&.with-value {
			white-space: normal;
			background-color: $primary_lavender_25 !important;
			border-color: $primary_lavender_50 !important;
		}

		&:hover {
			&.add {
				background-color: $secondary_sunflower_200 !important;
			}

			&.with-value {
				background-color: $primary_lavender_50 !important;
				border-color: $primary_lavender_75 !important;
			}
		}

		&.focus {
			color: $primary_blueberry_100 !important;
			pointer-events: none;

			&.add {
				background-color: $secondary_sunflower_50 !important;
				border-color: $secondary_sunflower_75 !important;
			}

			&.with-value {
				border-color: $primary_lavender_75 !important;
			}

			& > img {
				opacity: 0.5;
			}
		}

		& > img {
			margin-top: 1px;
		}
	}

	.dialog {
		.error {
			font-size: 10px;
			line-height: 12px;
			font-weight: $medium;
		}

		.input {
			&.single {
				margin: 0;
				width: 100%;
			}
		}

		.variable-value-group {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 16px;

			.input {
				&:first-child {
					flex: 0 1 calc(40% - 4px);
				}

				&:last-child {
					flex: 0 1 calc(60% - 4px);
				}
			}
		}

		.actions {
			display: flex;
			justify-content: flex-end;
			margin-top: 16px;

			.action {
				& + .action {
					margin-left: 8px;
				}
			}
		}
	}
}
