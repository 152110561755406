@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700');
@import 'react-widgets/scss/styles.scss';
@import '../styles/variables';
@import '../styles/mixins';

* {
	box-sizing: border-box;
	font-family: 'Lexend', sans-serif;
}

html,
body,
#root {
	min-height: 100vh;
	margin: 0px;
	padding: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: $normal;
	color: $primary_blueberry_400;
}

#root {
	display: flex;
}

#portals {
	position: relative;
	z-index: 5;
}

//Scroll prohibiting
.non-scrollable {
	overflow: hidden;
}

p {
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	padding: 0;
}

strong {
	font-weight: $semiBold;
}

::-webkit-scrollbar {
	border-radius: 24px;
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $primary_lavender_50;
	border-radius: 24px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary_lavender_75;
	border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $primary_lavender_75;
	cursor: pointer;
}
