@import '../../../styles/variables';

.primary-framework-actions {
	.actions {
		margin-top: 0;

		.action {
			&.with-loader {
				border: none;
				background: linear-gradient(90deg, #a195eb 33%, #7967e3 50%, #a195eb 66%) #f2f2f2;
				background-size: 300% 100%;
				animation: l1 1s infinite linear;
			}

			@keyframes l1 {
				0% {
					background-position: right;
				}
			}
		}
	}

	.status-info {
		margin-top: 0;
		margin-bottom: 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
