@import '../../styles/variables';

.onboarding {
	width: 683px;
	min-height: 400px;
	position: relative;
	padding: 32px 24px;

	&.no-access {
		min-height: 164px;

		p {
			margin-top: 40px;
		}
	}

	&.analysis {
		min-height: 232px;
	}

	.progress {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			background: url('../../assets/images/logo-gravity-grc-for-light-bg.svg') no-repeat;
			background-size: contain;
			width: 116px;
			height: 20px;
		}
	}

	.error {
		margin-top: 24px;
		text-align: center;
		font-weight: $semiBold;
	}
}
