@import '../../../styles/variables.scss';

.file-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 8px 16px;

	&:hover {
		background-color: $primary_lavender_25;
	}

	.name {
		max-width: 80%;
		position: relative;
		padding-left: 28px;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: calc(50% - 11px);
			background-repeat: no-repeat;
			background-size: contain;
			width: 20px;
			height: 20px;
		}

		&.uploading {
			&:before {
				background-image: url('../../../assets/images/icon-uploading-file.svg');
			}
		}

		&.error {
			color: $system_red_500;

			&:before {
				background-image: url('../../../assets/images/icon-failed-file-upload.svg');
			}
		}

		&.success {
			color: $system_green_500;

			&:before {
				background-image: url('../../../assets/images/icon-uploaded-file.svg');
			}
		}
	}

	.name,
	.progress {
		font-size: 14px;
		line-height: 20px;
		color: $primary_blueberry_200;
	}

	.actions {
		.action-buttons {
			display: flex;
			flex-direction: row;
			
			.action-button {
				display: block;
			}
		}
	}
}

.error {
	padding: 0 16px;
	margin-top: 0;
	font-size: 11px;
}
