@import '../../../styles/variables.scss';

.basic-panel {
	background-color: $white;
	border-radius: 16px;
	border: 0.5px solid $primary_lavender_75;
	padding: 32px;
	position: relative;
	z-index: 0;

	.title {
		margin-bottom: 40px;
	}

	&.with-logo {
		h2 {
			text-align: left;
			padding-right: 100px;
		}

		&:before {
			content: '';
			background: url('../../../assets/images/logo-gravity-grc-for-light-bg.svg') no-repeat;
			background-size: contain;
			position: absolute;
			right: 32px;
			top: 38px;
			width: 115px;
			height: 20px;
		}
	}

	&.with-back {
		.title {
			padding-left: 36px;
		}

		&.with-logo {
			&:before {
				width: 92px;
				height: 16px;
				top: 40px;
			}
		}
	}
}
