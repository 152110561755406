@import '../../../styles/variables';
@import '../../../styles/mixins';

.drawer {
	transition: background 200ms linear;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;

	.title {
		margin-bottom: 24px;
	}

	.drawer-content {
		overflow-y: auto;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: $white;
		min-width: 760px;
		width: 46%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		z-index: 12;

		.title {
			color: $primary_blueberry_500;
		}

		.close {
			top: 28px;
			right: 24px;
		}

		.expand-tool {
			position: absolute;
			top: 28px;
			right: 56px;
			cursor: pointer;
			width: 24px;
			height: 24px;
			padding: 6px;
			border-radius: 4px;

			&:before {
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				background-repeat: no-repeat;
				background-size: contain;
			}

			&.minimized {
				&:before {
					background-image: url('../../../assets/images/icon-expand.svg');
				}
			}

			&.expanded {
				&:before {
					background-image: url('../../../assets/images/icon-minimize.svg');
					transform: scaleX(-1);
				}
			}

			&:hover,
			&:active,
			&:focus {
				background-color: $primary_lavender_50;
			}
		}

		.content-wrap {
			position: relative;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
	}

	&.open {
		height: 100%;
		width: 100%;
		z-index: 4;
	}
}
