@import '../../../../../styles/variables';

.options-list {
	width: 100%;
	padding: 8px 12px 8px 8px;
	max-height: 160px;

	.option-group {
		display: flex;
		flex-direction: row;
		white-space: nowrap;

		& + .option-group {
			margin-top: 12px;
		}

		.tag {
			margin: 0;
			margin-left: 8px;
		}

		.text {
			margin-left: 8px;
		}
	}

	.search {
		width: 180px;
		margin-bottom: 16px;
	}
}
