@import '../../styles/variables';
@import '../../styles/mixins';

.login {
	width: 448px;
	min-height: 400px;

	.form {
		.password {
			margin-top: 24px;
		}

		.actions {
			margin: 40px 0;
		}
	}

	.link-wrapper {
		text-align: center;
		font-size: 0;

		.reset-link {
			@extend %link;
		}
	}

	.error {
		margin-top: 16px;
		text-align: center;
	}
}
