@import '../../../styles/variables';

.gs-updates {
	.title-section {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;
		align-items: center;
		justify-content: space-between;

		h3 {
			margin-bottom: 0;
		}
	}

	.post {
		display: flex;
		flex-direction: row;
		padding: 4px;

		& + .post {
			margin-top: 8px;
		}

		.picture {
			width: 120px;
			height: 96px;
			border-radius: 8px;
			flex-shrink: 0;
			margin-right: 12px;

			img {
				border-radius: 8px;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			&.empty {
				background-color: $primary_blueberry_50;
			}
		}

		.info {
			.label {
				margin: 0;
			}

			p.text {
				margin-top: 8px;
				font-size: 12px;
				line-height: 16px;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;

				.title {
					font-weight: $semiBold;
					line-height: 18px;
				}
			}
		}
	}
}
