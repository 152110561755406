@import '../../../styles/variables';

.tags-block {
	.tag {
		&.added {
			background-color: $system_green_50;
			border-color: $system_green_75 !important;
		}
	}

	.actions {
		margin-top: 24px;
		text-align: right;

		.action {
			& + .action {
				margin-left: 8px;
			}
		}
	}

	.empty {
		font-size: 14px;
		line-height: 20px;
		font-weight: $normal;
		color: $primary_blueberry_100;
	}
}
