@import '../../../styles/variables';
@import '../../../styles/mixins';

.table-filters {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px 16px 4px;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}

	.left-side,
	.right-side {
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.left-side {
		margin-left: -4px;
		margin-top: -8px;
	}

	.right-side {
		justify-content: flex-end;
	}
}
