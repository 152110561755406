@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.filter {
	display: inline-block;
	margin: 8px 4px 4px;

	select {
		background-size: 16px !important;
        color: $primary_blueberry_200 !important;
	}
}
