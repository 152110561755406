@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.textarea-wrap {
	textarea {
		border: 1px solid $primary_lavender_75;
		border-radius: 12px;
		padding: 8px 12px;
		resize: none;
		background-color: $primary_lavender_25 !important;
		font-size: 14px !important;
		line-height: 20px;
		outline: none;
		width: 100%;
		min-height: 80px;
		max-height: 136px;
		transition: all 0.2s linear;
		color: $primary_blueberry_200;

		&::placeholder {
			color: $primary_blueberry_100;
		}

		&:hover {
			background-color: $primary_lavender_50 !important;
		}

		&:focus {
			border-color: $primary_lavender_200;
		}

		&:disabled,
		&:read-only {
			background-color: $primary_lavender_25 !important;
			color: $primary_blueberry_75;
			border-color: $primary_lavender_50;
			cursor: not-allowed;

			&::placeholder {
				color: $primary_blueberry_75;
			}
		}

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			margin: 8px 8px 8px 0;
		}
	}

	&.error {
		textarea {
			border-color: $system_red_300;
		}
	}

	&.disabled {
		&.error {
			textarea {
				border-color: $system_red_75;
			}
		}
	}

	&.as-input {
		textarea {
			min-height: 38px;
			height: 38px;
		}
	}
}

.label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	font-weight: $medium;
	color: $primary_blueberry_200;
	margin-bottom: 4px;
}
