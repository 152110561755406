@import '../../../styles/variables';

.instructions {
	p,
	.item {
		font-size: 12px;
		line-height: 15px;
		color: $primary_blueberry_200;
	}

	.items {
		margin-top: 8px;
		padding-left: 4px;

		.item {
			& + .item {
				margin-top: 8px;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: $primary_blueberry_200;
				margin: 2px 8px 2px 2px;
			}

			&.invalid:before,
			&.valid:before {
				background-color: transparent;
				background-repeat: no-repeat;
				background-size: contain;
			}

			&.invalid {
				color: $system_red_500;

				&:before {
					background-image: url('../../../assets/images/icon-cross-error.svg');
					width: 8px;
					height: 8px;
					margin: 0 10px 0 0;
				}
			}

			&.valid {
				color: $system_green_500;

				&:before {
					background-image: url('../../../assets/images/icon-check-success.svg');
					width: 10px;
					height: 8px;
					margin: 0 8px 0 0;
				}
			}
		}
	}
}
