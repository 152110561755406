@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.drawer {
	padding-right: 8px !important;
}

.edit-shareholder {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.details {
			margin-top: 0;
		}

		.content {
			max-height: calc(100vh - 200px);
		}

		.scrollbar {
			padding-right: 16px;
		}

		.input {
			& + .input {
				margin-top: 24px;
			}
		}

		.input-group {
			margin: 24px 0;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;

			.input {
				margin-top: 0;
				flex: 0 1 calc(50% - 8px);
			}
		}

		.actions {
			padding-right: 16px;
		}
	}

	.collapsible-trigger {
		margin-bottom: 0;
	}
}
