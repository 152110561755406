@import "../../../../../styles/variables";

.tags-list {
    padding: 6px 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-width: 100px;

    .placeholder {
        color: $primary_blueberry_100;
    }
}