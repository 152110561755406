@import '../../../../styles/variables';

.recent-tags {
	.title-section {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;
		align-items: center;
		justify-content: space-between;

		h3 {
			margin-bottom: 0;
		}
	}

	.row {
		pointer-events: none;
	}

	.empty-message {
		text-align: center;
		margin-top: 32px;

		.message {
			position: relative;
			display: inline-block;
			color: $primary_blueberry_100;
			font-size: 14px;

			&:before {
				content: '';
				display: block;
				width: 64px;
				height: 64px;
				background: url('../../../../assets//images/icon-empty-box.svg') no-repeat center;
				background-size: contain;
				margin: 0 auto 16px;
			}
		}
	}
}
