@import '../../../styles/variables';
@import '../../../styles/mixins';

.modal {
	transition: background 200ms linear;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, 0.2);
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 100%;
}
