@import '../../../../styles/variables';

.basic-panel {
	padding: 0 0 12px;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.delete {
	margin-top: 8px;
	margin-left: 8px;
}

.colum-text {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.left {
	display: flex;
    flex-wrap: wrap;
	
	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.tag {
	margin: 0;
}
