@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.asset-details {
	min-height: calc(100% - 62px);

	.details {
		margin-top: 0;
		margin-bottom: 0;
	}

	.collapsible-trigger {
		margin-bottom: 0;
	}
}

.asset-details-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.section {
		margin-top: 32px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.item {
		& + .item {
			margin-top: 16px;
		}
	}
}