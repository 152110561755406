@import '../../../styles/variables';

.updates {
	h3 {
		margin-bottom: 16px;
	}

	.empty-message {
		text-align: center;

		.message {
			position: relative;
			display: inline-block;
			color: $primary_blueberry_100;
			font-size: 14px;

			&:before {
				content: '';
				display: block;
				width: 64px;
				height: 64px;
				background: url('../../../assets/images/icon-empty-updates.svg') no-repeat center;
				background-size: contain;
				margin: 0 auto 16px;
			}
		}
	}

	.scrollbar {
		padding-right: 16px;

		.history {
			max-height: 447px;
		}
	}
}

.updates-skeleton {
	* {
		display: block;
	}

	br {
		display: none;
	}

	.item {
		& + .item {
			margin-top: 16px;
		}
	}
}
