@import '../../../styles/variables';
@import '../../../styles/mixins';

.tab {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 108px;
	height: 36px;
	border-radius: 8px;
	background-color: transparent;
	padding: 8px 16px;
	font: {
		size: 14px;
		weight: $semiBold;
	}
	line-height: 20px;
	color: $primary_blueberry_500;
	text-align: center;
	text-decoration: none;
	transition: all 0.3s;
	cursor: pointer;
	outline: none;

	&.active {
		background-color: $primary_lavender_75;
	}

	&:not(:last-child) {
		margin-right: 8px;
	}
}
