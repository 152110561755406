@import '../../../../styles/variables';

.table-wrap {
	margin-top: 32px;

	.row {
		pointer-events: none;

		& > * {
			padding-top: 8px !important;
			padding-bottom: 8px !important;
		}
	}
}
