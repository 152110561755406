@import '../../../styles/variables';

.statement-item {
	.help {
		font-size: 12px;
		margin-top: 12px;

		.info {
			display: inline-block;
		}
	}

	.tag {
		margin: 0;
		cursor: pointer;
		color: $primary_blueberry_200 !important;

		&.disabled {
			opacity: 0.7;
			cursor: not-allowed;
			pointer-events: none;
		}

		&.add {
			background-color: $secondary_sunflower_75 !important;
			border-color: $secondary_sunflower_200 !important;
		}

		&.with-value {
			border-radius: 4px;
			padding: 6px 12px;
			font-size: 14px;
			font-weight: $normal;
			text-transform: none;
			white-space: break-spaces;
			background-color: $primary_lavender_25 !important;
			border-color: $primary_lavender_50 !important;
			width: 100%;

			img {
				margin-top: 3px;
			}
		}

		&:hover {
			&.add {
				background-color: $secondary_sunflower_200 !important;
			}

			&.with-value {
				background-color: $primary_lavender_50 !important;
				border-color: $primary_lavender_75 !important;
			}
		}

		&.focus {
			pointer-events: none;
			color: $primary_blueberry_100 !important;

			&.add {
				background-color: $secondary_sunflower_50 !important;
				border-color: $secondary_sunflower_75 !important;
			}

			&.with-value {
				border-color: $primary_lavender_75 !important;
			}

			& > img {
				opacity: 0.5;
			}
		}

		& > img {
			margin-top: 1px;
		}
	}

	.dialog {
		width: 100%;
		margin-top: 12px;

		.actions {
			display: flex;
			justify-content: flex-end;
			margin-top: 16px;

			.action {
				& + .action {
					margin-left: 8px;
				}
			}
		}
	}
}
