@import '../../../styles/variables.scss';

.tooltip-wrap {
	position: relative;

	.tooltip {
		z-index: 1000;
		width: 380px;
		padding: 16px;
		border-radius: 12px;
		border: 1px solid $primary-lavender_75;
		background: $primary-lavender_10;
		opacity: 1;

		&.bottom-start {
			border-radius: 12px;
			border-top-left-radius: 0;
		}

		&.bottom-end {
			border-radius: 12px;
			border-top-right-radius: 0;
		}
	}
}
