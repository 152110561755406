@import '../../../styles/variables';
@import '../../../styles/mixins';

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	font: {
		size: 14px;
		weight: $semiBold;
	}
	line-height: 20px;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s;
	outline: none;
	margin: 0;
	border: 2px solid $primary_lavender_300;
	border-radius: 12px;
	background-color: $primary_lavender_300;
	width: 100%;

	&.small {
		height: 32px;
		font-size: 12px;
		line-height: 16px;
	}

	.text {
		color: $primary_lavender_25;
	}

	&:hover {
		border-color: $primary_lavender_200;
		background-color: $primary_lavender_200;
	}

	&:disabled {
		cursor: auto;
		border-color: $primary_lavender_75;
		background-color: $primary_lavender_75;
		pointer-events: none;
	}

	&.with-icon {
		.text {
			padding-left: 20px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: calc(50% - 7px);
				background-size: contain;
				background-repeat: no-repeat;
			}

			&.next {
				padding-left: 0;
				padding-right: 30px;

				&:before {
					right: 0;
					left: auto;
					top: calc(50% - 5px);
					width: 16px;
					height: 12px;
					background-image: url('../../../assets/images/icon-arrow-right-white.svg');
				}
			}

			&.save:before {
				width: 15px;
				height: 15px;
				background-image: url('../../../assets/images/icon-save-white.svg');
			}

			&.archive:before {
				width: 17px;
				height: 15px;
				background-image: url('../../../assets/images/icon-archive-white.svg');
			}
		}

		&:hover {
			&.with-icon {
				.text {
					&.next:before {
						background-image: url('../../../assets/images/icon-arrow-right-accent.svg');
					}

					&.save:before {
						background-image: url('../../../assets/images/icon-save-accent.svg');
					}

					&.archive:before {
						background-image: url('../../../assets/images/icon-archive-accent.svg');
					}
				}
			}
		}
	}

	&.error {
		background-color: $system_red_300;
		border-color: $system_red_300;

		&:hover {
			background-color: $white;
			border-color: $system_red_300;

			.text {
				color: $system_red_300;
			}
		}
	}

	&.negative {
		background-color: transparent;
		border-color: $primary_lavender_300;

		.text {
			color: $primary_lavender_300;
		}

		&:disabled {
			border-color: $primary_lavender_75;

			.text {
				color: $primary_lavender_75;
			}
		}

		&:hover {
			border-color: $primary_lavender_200;
			background-color: $primary_lavender_50;

			.text {
				color: $primary_lavender_200;
			}

			&.with-icon {
				.text {
					&.next:before {
						background-image: url('../../../assets/images/icon-arrow-right-white.svg');
					}

					&.save:before {
						background-image: url('../../../assets/images/icon-save-white.svg');
					}

					&.archive:before {
						background-image: url('../../../assets/images/icon-archive-white.svg');
					}
				}
			}
		}

		&.with-icon {
			.text {
				&.next:before {
					background-image: url('../../../assets/images/icon-arrow-right-accent.svg');
				}

				&.save:before {
					background-image: url('../../../assets/images/icon-save-accent.svg');
				}

				&.archive:before {
					background-image: url('../../../assets/images/icon-archive-accent.svg');
				}
			}
		}

		&.error {
			border-color: $system_red_300;

			.text {
				color: $system_red_300;
			}

			&:hover {
				background-color: $system_red_300;

				.text {
					color: $white;
				}
			}
		}
	}
}
