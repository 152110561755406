@import '../../../styles/variables.scss';

.color-picker {
	.color {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin: 0 8px;
		display: inline-block;
		cursor: pointer;
		border: 1px solid $primary_lavender_75;

		&:first-child {
			margin-left: 0;
		}

		&.active {
			border: 2px solid $primary_lavender_200;
		}
	}
}
