@import '../../../styles/variables';

.variables-wrap {
	position: relative;
	margin-bottom: 24px;

	.variables {
		margin-top: 24px;
		white-space: break-spaces;

		.text {
			display: inline;
			margin-bottom: 8px;
			color: $primary_blueberry_500;
			line-height: 20px;
		}
	}
}
