@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.change-password {
	flex-grow: 1;

	form {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.inputs {
			.input {
				& + .input {
					margin-top: 24px;
				}
			}

			.instructions {
				margin-top: 24px;
			}
		}
	}
}
