@import '../../../styles/variables.scss';

.action-button {
	cursor: pointer;
	width: 24px;
	height: 24px;
	padding: 4px;
	border-radius: 4px;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		top: calc(50% - 8px);
		left: calc(50% - 8px);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		position: absolute;
	}

	&.delete-user:before {
		background-image: url('../../../assets/images/icon-button-delete-user.svg');
		width: 24px;
		height: 24px;
		top: calc(50% - 14px);
		left: calc(50% - 12px);
	}

	&.reminder:before {
		background-image: url('../../../assets/images/icon-button-reminder.svg');
		width: 24px;
		height: 24px;
		top: calc(50% - 12px);
		left: calc(50% - 12px);
	}

	&.download:before {
		background-image: url('../../../assets/images/icon-button-download-doc.svg');
	}

	&.copy:before {
		background-image: url('../../../assets/images/icon-button-copy-doc.svg');
	}

	&.return:before {
		background-image: url('../../../assets/images/icon-button-back.svg');
	}

	&.resume:before {
		background-image: url('../../../assets/images/icon-resume.svg');
	}

	&.cancel:before {
		background-image: url('../../../assets/images/icon-close-circle.svg');
	}

	&.archive:before {
		background-image: url('../../../assets/images/icon-archive.svg');
	}

	&.loading:before {
		content: 'Copying...';
	}

	&.pencil:before {
		background-image: url('../../../assets/images/icon-pencil.svg');
	}

	&.close:before {
		background-image: url('../../../assets/images/icon-close-x.svg');
	}

	&.save:before {
		background-image: url('../../../assets/images/icon-save.svg');
	}

	&:hover {
		background-color: $primary_lavender_50;
	}
}
