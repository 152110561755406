@import '../../../../styles/variables';

.info {
	margin-bottom: 24px;
}

.form {
	.confirm-password {
		margin-top: 24px;
	}

	.actions {
		margin-top: 40px;
	}

	.instructions {
		margin-top: 24px;
	}
}

.loader {
	margin-top: 60%;
}
