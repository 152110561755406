@import '../../../../styles/variables';

.table-wrap {
	margin-top: 16px;
	position: relative;

	.search {
		position: absolute;
		top: -68px;
		right: 0;
		width: 344px;
	}

	.row {
		& > * {
			padding-top: 8px !important;
			padding-bottom: 8px !important;
		}
	}
}
