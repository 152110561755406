@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 393px;
	height: auto;
	padding: 32px 24px;

	.title {
		margin-bottom: 24px;
	}

	.text {
		margin-bottom: 10px;
	}
}

.clear {
	cursor: pointer;
	background-image: url('../../../../assets/images/icon-cross-thin-accent-dark.svg');
	background-size: 12px 12px;
	background-repeat: no-repeat;
	background-position: center;
	width: 32px;
	height: 100%;

	&:hover {
		background-color: $primary_lavender_50;
	}
}
