@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.checkbox {
	display: block;
	line-height: 1;

	&.disabled {
		opacity: 0.5;
	}

	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		transition: all 0.2s linear;
		line-height: 1;
	}

	label {
		position: relative;
		cursor: pointer;
		font-size: 12px;
		line-height: 1;
	}

	label:before {
		content: '';
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		border: 1.5px solid $primary_lavender_75;
		border-radius: 4px;
		width: 18px;
		height: 18px;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		cursor: pointer;
		line-height: 1;
	}

	input:checked + label {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 2px);
			left: calc(50% - 6px);
			width: 12px;
			height: 8px;
			background: url('../../../../assets/images/icon-check-white.svg') no-repeat;
			background-size: contain;
		}

		&:before {
			background-color: $primary_lavender_300;
			border-color: $primary_lavender_300;
		}
	}
}
