@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.basic-panel {
	width: 712px;
	min-height: 240px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.title {
		margin-bottom: 24px;
		line-height: 40px;
	}

	.text {
		margin-top: 16px;

		.empty {
			text-align: center;
			color: $primary_blueberry_100;
		}
	}

	.controls  {
		margin-top: 16px;
	}

	.actions {
		margin-top: 32px;
	}
}
