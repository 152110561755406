@import '../../../styles/variables';

.steps {
	margin-bottom: 40px;
	height: 24px;

	.step {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: $primary_lavender_300;
		margin: 0 8px;
		vertical-align: middle;
		transition: all 0.3s linear;

		&.small {
			width: 8px;
			height: 8px;
		}

		&.active {
			width: 24px;
			height: 24px;

			&.small {
				width: 16px;
				height: 16px;
			}
		}

		&:first-child {
			margin-left: 0;
		}
	}
}
