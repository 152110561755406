@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.search {
	display: inline-block;
	width: 260px;
	margin: 8px 4px 4px;

	@include breakpoint(0, $mediumDesktop) {
		width: 224px;
	}
}
