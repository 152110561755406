@import '../../../styles/variables';

.add-tag {
	display: inline-block;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	font-weight: $semiBold;
	padding: 4px 8px;
	border-radius: 4px;
	margin: 2px;
	padding-left: 32px;
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		left: 8px;
		top: calc(50% - 8px);
		background: url('../../../assets/images/icon-add-circle.svg') no-repeat;
		background-size: contain;
		width: 16px;
		height: 16px;
	}

	&.disabled {
		pointer-events: none;
	}
}
