@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.select-popup {
	border: 1px solid $primary_lavender_75;
	background-color: $primary_lavender_25;
	border-radius: 16px;
	border-top-right-radius: 0;
	margin-top: 4px;
	position: absolute;
	top: 100%;
	right: 0;
	display: none;
	min-width: 100%;
	max-width: max-content;
	overflow: hidden;
	max-height: 180px;

	&.opened {
		display: block;
		z-index: 15;
	}

	&.in-table {
		right: 13px;
	}
}

//.rw-widget-input
.select-input {
	border: 1px solid $primary_lavender_75;
	border-radius: 12px;
	background-color: $primary_lavender_25;
	color: $primary_blueberry_200;
	font-size: 14px;
	line-height: 20px;
	outline: none;
	box-shadow: none;
	transition: all 0.1s linear;
	grid-template: none;
	cursor: pointer;
	padding: 8px 24px 8px 0;

	&:after {
		content: '';
		position: absolute;
		top: calc(50% - 4px);
		right: 20px;
		width: 12px;
		height: 8px;
		background-image: url('../../../../assets/images/icon-caret-down-light.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&.opened {
		border-bottom-right-radius: 0;

		&:after {
			transform: rotate(180deg);
			background-image: url('../../../../assets/images/icon-caret-down-dark.svg');
		}
	}

	&.small-carret {
		&:after {
			top: calc(50% - 4px);
			right: 18px;
			width: 10px;
			height: 10px;
		}
	}

	&.in-table {
		border-color: transparent;
		background-color: transparent;
		margin: -9px -13px;

		&:after {
			right: 24px;
		}
	}
}

.select-wrapper {
	min-width: 150px;
	transition: all 0.2s linear;

	.label {
		display: flex;
		align-items: center;
		font-size: 10px;
		line-height: 12px;
		font-weight: $medium;
		color: $primary_blueberry_200;
		margin-bottom: 4px;

		.loader {
			margin-left: 6px;
		}
	}

	:global {
		.rw-placeholder,
		.rw-input::placeholder {
			color: $primary_blueberry_100;
		}

		.rw-multiselect-input:placeholder {
			color: $primary_blueberry_100 !important;
		}

		.rw-state-focus {
			.rw-widget-input {
				box-shadow: none;
				background-color: $primary_lavender_25;
				border-color: $primary_lavender_200;
			}
		}

		.rw-state-disabled {
			.rw-widget-input {
				border-color: $primary_lavender_50;
				background-color: $primary_lavender_25;
				color: $primary_blueberry_75;
				cursor: not-allowed;
			}

			.rw-placeholder,
			.rw-input::placeholder {
				color: $primary_blueberry_75;
			}
		}

		.rw-picker-caret {
			display: none !important;
		}
	}

	&.error {
		:global {
			.rw-widget-input {
				border-color: $system_red_300;
			}

			.rw-state-disabled {
				.rw-widget-input {
					border-color: $system_red_75;
				}
			}
		}
	}
}
