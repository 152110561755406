@import 'variables';

@mixin fixed($top: 0, $right: auto, $bottom: auto, $left: 0) {
	position: fixed;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolute($top: 0, $right: auto, $bottom: auto, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin breakpoint($minResolution: null, $maxResolution: null) {
	@if $minResolution and $maxResolution {
		@media all and (min-width: $minResolution) and (max-width: ($maxResolution - 1)) {
			@content;
		}
	} @else if $minResolution {
		@media all and (min-width: $minResolution) {
			@content;
		}
	}
}

%link {
	display: inline-block;
	font-size: 12px;
	line-height: 16px;
	font-weight: $semiBold;
	text-decoration: none;
	color: $primary_lavender_300;
	padding: 2px 4px;
	border-radius: 4px;

	&:hover,
	&:active,
	&:focus {
		background-color: $primary_lavender_50;
	}
}

//Clear fix
%clearfix {
	&::after {
		content: '';
		display: table;
		height: 0;
		width: 0;
		clear: both;
	}
}
