@import '../../../../styles/variables';

.table-wrap {
	.search {
		margin-top: 0;
		margin-bottom: 16px;
		width: 253px;
	}

	.row {
		align-items: center !important;
		cursor: default;

		&:hover {
			background: transparent !important;
		}
	}

	.assign {
		padding: 0px 0px 16px;
	}

	.action-button {
		top: -6px;
	}
}

.date-tag {
	margin: -4px 0;
}

.related-item {
	position: relative;
	padding: 0 4px;

	&:hover {
		&:before {
			content: '';
			position: absolute;
			background-color: #f9f9ff;
			border-radius: 4px;
			width: 100%;
			height: 36px;
			display: block;
			top: -8px;
			left: -4px;
			z-index: -1;
		}
	}

	h4 {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}
}
