@import '../../../styles/variables';

.location-search {
	position: relative;

	.options-list {
		border: 1px solid $primary_lavender_75;
		background-color: $primary_lavender_25;
		border-radius: 16px;
		margin-top: 4px;
		position: absolute;
		top: 100%;
		right: 0;
		min-width: fit-content;
		display: block;
		width: 100%;
		overflow: hidden;
		z-index: 1;
	}
}
