//Resolutions
$smallDesktop: 1280px;
$mediumDesktop: 1440px;
$largeDesktop: 1920px;

//Font weights
$light: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$demiBold: 700;
$bold: 900;

$black: #000;
$white: #fff;

//---

$primary_lavender_10: #FCFCFF;
$primary_lavender_25: #f9f9ff;
$primary_lavender_50: #efedfc;
$primary_lavender_75: #bdb4f1;
$primary_lavender_100: #a195eb;
$primary_lavender_200: #7967e3;
$primary_lavender_300: #5d48dd;
$primary_lavender_400: #41329b;
$primary_lavender_500: #392c87;

$primary_blueberry_50: #e7e7eb;
$primary_blueberry_75: #9d9dac;
$primary_blueberry_100: #757589;
$primary_blueberry_200: #393957;
$primary_blueberry_300: #111134;
$primary_blueberry_400: #0c0c24;
$primary_blueberry_500: #0a0a20;

$system_red_50: #fcedf0;
$system_red_75: #f1b4c3;
$system_red_200: #e36785;
$system_red_300: #dd486c;
$system_red_500: #c33c5d;

$system_green_50: #effced;
$system_green_75: #bdf1b4;
$system_green_200: #79e367;
$system_green_300: #5ddd48;
$system_green_500: #47a738;

$system_orange_50: #fff6f0;
$system_orange_75: #ffdbc1;
$system_orange_200: #ffb681;
$system_orange_300: #ffa767;
$system_orange_500: #e68d4c;

$secondary_leaf_75: #d1f0ab;

$secondary_sunflower_50: #FFFCEA;
$secondary_sunflower_75: #fff1aa;
$secondary_sunflower_200: #FFE453;

$secondary_sky_75: #cae1fe;
$secondary_sky_200: #93C2FD;

$secondary_rose_75: #F1CCFE;
$secondary_rose_200: #E298FD;

$secondary_peach_75: #FFC3B4;
$secondary_peach_200: #FF8566;
