@import '../../../styles/variables.scss';

.remove-action {
	text-align: center;

	.text {
		cursor: pointer;
		padding: 2px 4px;
		border-radius: 4px;
		font-weight: $semiBold;
		font-size: 12px;
		line-height: 16px;
		color: $primary_lavender_300;

		&:after {
			content: '';
			background: url('../../../assets/images/icon-cross-circle-accent.svg') no-repeat;
			background-size: contain;
			width: 14px;
			height: 14px;
			margin-left: 4px;
			display: inline-block;
			vertical-align: text-top;
		}
	}

	&:hover {
		.text {
			background-color: $primary_lavender_50;
		}
	}
}
